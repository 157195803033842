import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateTimeLabel", "dateField", "timeField"];

  toggleCustomFields(event) {
    const isCustom = event.target.value === "0";

    // Toggle classes on label
    if (isCustom) {
      this.dateTimeLabelTarget.classList.remove("text-muted");
      this.dateTimeLabelTarget.classList.add("text-alternate");
    } else {
      this.dateTimeLabelTarget.classList.remove("text-alternate");
      this.dateTimeLabelTarget.classList.add("text-muted");
    }

    // Toggle disabled state on date/time fields
    this.dateFieldTarget.disabled = !isCustom;
    this.timeFieldTarget.disabled = !isCustom;
  }
}
