import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["hiddenInput"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".bi-list", // Specify the handle for dragging
      animation: 150, // Smooth animations
      onEnd: this.updatePositions.bind(this), // Update positions when dragging ends
    });
  }

  updatePositions() {
    // Collect the new order of items
    const newOrder = {};
    this.element.querySelectorAll("[data-id]").forEach((item, index) => {
      newOrder[item.dataset.id] = index + 1; // Map item ID to new position
    });

    // Update hidden input fields with the new order
    this.hiddenInputTargets.forEach((input) => {
      const id = input.name.match(/\[(\d+)\]/)[1]; // Extract item ID from input name
      input.value = newOrder[id]; // Update value with new position
    });
  }
}
