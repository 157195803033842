import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "firstNameInput",
    "lastNameInput",
    "emailInput",
    "accountInput",
  ];

  updateContactId(event) {
    const contactId = event.currentTarget.dataset.contactId;
    const fullName = event.currentTarget.dataset.fullName;

    this.setContactId(contactId, fullName);
  }

  // Show / Hide email option if the user chooses to create a new email
  toggleAddNewEmail() {
    const emailContainer = document.querySelector("#add_new_contact");
    emailContainer?.classList.toggle("d-none");
  }

  // Handle adding a new email and updating dropdowns
  async addToEmailNewvalue(event) {
    event.preventDefault();

    const emailInput = this.emailInputTarget;
    const firstName = this.firstNameInputTarget.value.trim();
    const lastName = this.lastNameInputTarget.value.trim();
    const newEmail = emailInput.value.trim();
    const accountId = this.accountInputTarget.value.trim();
    const errorContainer = document.querySelector("#generalErrorContainer");
    const newContainer = document.querySelector("#add_new_contact");

    // Validate inputs
    const isValid = this.validateFields(
      firstName,
      lastName,
      newEmail,
      errorContainer,
    );

    if (!isValid) return;

    try {
      const response = await fetch(
        `/accounts/${accountId}/create_new_contact?email=${encodeURIComponent(newEmail)}&first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}&account_id=${encodeURIComponent(accountId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const data = await response.json();

      if (response.ok) {
        // Update dropdown lists
        this.addContactToDropdown(
          data.full_name,
          data.contact_id,
          document.querySelector("#contact_dropdown"),
        );

        // Reset the form and hide the container
        this.resetForm();
        newContainer?.classList.add("d-none");
      } else {
        this.displayErrors(
          data.errors || ["Failed to save the email."],
          errorContainer,
        );
      }
    } catch (error) {
      console.error("Error while adding new email:", error);
      this.displayErrors(
        ["An unexpected error occurred. Please try again."],
        errorContainer,
      );
    }
  }

  // Validate form fields and show error messages if needed
  validateFields(firstName, lastName, email, errorContainer) {
    let isValid = true;
    if (!firstName) {
      this.showValidationError(
        this.firstNameInputTarget,
        "Please enter a first name.",
      );
      isValid = false;
    }
    if (!lastName) {
      this.showValidationError(
        this.lastNameInputTarget,
        "Please enter a last name.",
      );
      isValid = false;
    }
    if (!email) {
      this.showValidationError(this.emailInputTarget, "Please enter an email.");
      isValid = false;
    } else {
      errorContainer?.classList.add("d-none");
    }
    return isValid;
  }

  // Add an email to the dropdown
  addContactToDropdown(fullName, contactId, dropdown) {
    if (!dropdown) {
      console.error("Dropdown element not found.");
      return;
    }

    // Create a new anchor element for the contact
    const contactElement = document.createElement("a");
    contactElement.classList.add("dropdown-item", "p-1");
    contactElement.textContent = fullName;
    contactElement.dataset.contactId = contactId;
    contactElement.setAttribute("href", "#");
    contactElement.setAttribute("data-value", fullName);
    contactElement.setAttribute("data-action", "contact#addToEmailNewvalue");

    // Locate the divider inside the dropdown
    const divider = dropdown.querySelector(".dropdown-divider");

    // Safely insert the new contact element before the divider
    if (divider) {
      dropdown.prepend(contactElement, contactElement);
      this.setContactId(contactId, fullName);
    } else {
      console.warn("Divider not found. Appending to the end.");
    }

    // Update the dropdown button text
    const dropdownButton = document.querySelector("#contact_id");
    if (dropdownButton) {
      dropdownButton.innerHTML = fullName;
    } else {
      console.error("Dropdown button not found.");
    }
  }

  // Reset the form fields
  resetForm() {
    this.firstNameInputTarget.value = "";
    this.lastNameInputTarget.value = "";
    this.emailInputTarget.value = "";
  }

  // Show validation error for a specific input
  showValidationError(input, message) {
    const validationMessage = input.nextElementSibling;
    if (validationMessage) {
      validationMessage.textContent = message;
      validationMessage.classList.remove("d-none");
    }
  }

  // Display error messages in the error container
  displayErrors(errors, errorContainer) {
    if (errorContainer) {
      errorContainer.classList.remove("d-none");
      errorContainer.innerHTML = errors.join("<br>");
    }
  }

  setContactId(contact_id, full_name) {
    const contact_field = document.querySelector("#hidden_contact_field");
    const dropdownButton = document.querySelector("#contact_id");

    contact_field.value = contact_id;

    // Update the dropdown button text
    if (dropdownButton) {
      dropdownButton.innerHTML = full_name;
    } else {
      console.error("Dropdown button not found.");
    }
  }
}
