import Reveal from "stimulus-reveal-controller";

export default class extends Reveal {
  static initialized = false;
  static isToggled = false;

  connect() {
    if (!this.constructor.initialized) {
      this.handleClickOutside = this.handleClickOutside.bind(this);
      document.addEventListener("click", this.handleClickOutside);
      this.constructor.initialized = true;
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
    this.constructor.initialized = false; // Reset for new pages
  }

  toggle(event) {
    const isThreeDotsButton =
      event.target.classList.contains("bi-three-dots-vertical") ||
      (event.target.children[0] &&
        event.target.children[0].classList.contains("bi-three-dots-vertical"));

    if (isThreeDotsButton) {
      event.stopPropagation();
      document.querySelectorAll(".its-popmenu:not(.d-none)").forEach((menu) => {
        if (menu !== this.itemTarget) {
          menu.classList.add("d-none");
        }
      });

      this.itemTarget.classList.toggle("d-none");
      return;
    }

    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.data.get("revealHiddenClass") || "d-none");
    });

    this.constructor.isToggled = true;
  }

  handleClickOutside(event) {
    if (
      event.target.dataset.closePopup &&
      event.target.dataset.closePopup === "false"
    ) {
      this.constructor.isToggled = false;
      return;
    }

    if (
      !this.element.contains(event.target) &&
      this.constructor.isToggled === false
    ) {
      document.querySelectorAll(".its-popmenu:not(.d-none)").forEach((menu) => {
        menu.classList.add("d-none");
      });
    }
    this.constructor.isToggled = false;
  }
}
